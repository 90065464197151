<template>
  <div class="form--group amenity--section">
    <div class="left--col">
      <span>{{ $t('general.amenity') }}</span>
    </div>
    <div class="right--col">
      <amenity-field
        v-for="(amenity, index) in amenities"
        :key="index"
        :index="index"
        :amenity="amenity"
        ref="amenityField"
        :amenityLength="amenities.length"
      />
      <div class="col-12 pa-0 mt-2">
        <button
          :disabled="amenities.length >= 5"
          @click="addAmenity"
          class="btn btn-primary--light btn-block"
          type="button"
          id="amenityBtn"
        >
          {{ $t('addListing.btn.addAmenity') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const AmenityField = () => import('@/components/listing-form/sect1/amenities/amenity-field.vue');
export default {
  name: 'amenities-section',
  components: { AmenityField },
  computed: {
    ...mapState({
      amenities: (state) => state.v2.listingForm.sect1.location.amenities,
    }),
  },
  methods: {
    addAmenity() {
      this.$store.commit('v2/listingForm/sect1/location/ADD_AMENITY');
      this.$nextTick(function () {
        this.$refs.amenityField[this.amenities.length - 1].$el.querySelector('input').focus();
      });
    },
    validate: function () {
      let self = this;
      let validationArray = [];
      const amenityValidation = this.$refs.amenityField
        ? this.$refs.amenityField.map(function (form) {
            return form.$validate();
          })
        : [];
      validationArray.push(amenityValidation);
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0 || !self.useProtocol
        );
      });
    },
  },
};
</script>

<style scoped></style>
